import * as React from "react";
import $ from "jquery";

class Carousel extends React.Component {
  constructor() {
    super();
    this.state = {
      slidePosition: null,
    }
  }
  componentDidMount() {
    // Hack to enable mobile swiping
    $('.dot.active').trigger('click');
    const checkitem = () => {
      if ($('.carousel-inner .carousel-item:first').hasClass('active')) {
        this.setState({ slidePosition: 'first' })
      } else if ($('.carousel-inner .carousel-item:last').hasClass('active')) {
        this.setState({ slidePosition: 'last' })
      } else {
        this.setState({ slidePosition: 'middle' })
      }
    }
    checkitem();
    const carousel = document.getElementById('carousel-quick-facts');
    carousel.addEventListener('slid.bs.carousel', checkitem)
  }

  render() {
    return (
      <>
        <h2>
          Quick Facts About HIV
        </h2>
        <div id="carousel-quick-facts" className="carousel slide" data-bs-interval="false" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="content one">
                <div>
                  <h3>
                    1 in 7 people living in the US with HIV didn't know they had&nbsp;it.<sup>†</sup>
                  </h3>
                  <p>
                    The ONLY way to know if you have HIV is to get tested.
                  </p>
                </div>
                <p className="footnote">
                  †According to 2018 data.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <div className="content two">
                <div>
                  <h3>
                    ~80% of new HIV infections were transmitted by people who didn’t know they had HIV or were not in&nbsp;care.<sup>†</sup>
                  </h3>
                  <p>
                    Once you get tested for HIV, you can work with a doctor to take the appropriate next steps.
                  </p>
                </div>
                <p className="footnote">
                  †According to a 2016 study.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <div className="content three">
                <div>
                  <h3>
                    There are different PrEP medication options available, some of which can be about 99% effective in reducing the chances of getting HIV from sex when taken as prescribed.
                  </h3>
                  <p>
                    Keep in mind, PrEP medications don't protect against other sexually transmitted infections (STIs). So be sure to use condoms and other healthy sex practices. Ask a healthcare 
                    professional if PrEP is right for you. 
                    <a href="/after-an-hiv-test">Learn what else you should do after taking an HIV test.</a>
                  </p>
                </div>
                <p className="footnote">
                  PrEP=pre-exposure prophylaxis.
                </p>
              </div>
            </div>
          </div>
          <div className="nav">
            <button className={`carousel-control-prev ${this.state.slidePosition === 'first' ? 'hide' : 'show'}`} type="button" data-bs-target="#carousel-quick-facts" data-bs-slide="prev">
              <img className="back" src="/images/global/triangle-blue.svg" />
              Back
            </button>
            <div className="carousel-indicators">
              <button className="dot active" type="button" data-bs-target="#carousel-quick-facts" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
              <button className="dot" type="button" data-bs-target="#carousel-quick-facts" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button className="dot" type="button" data-bs-target="#carousel-quick-facts" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <button className={`carousel-control-next ${this.state.slidePosition === 'last' ? 'hide' : 'show'}`} type="button" data-bs-target="#carousel-quick-facts" data-bs-slide="next">
              Next
              <img className="next" src="/images/global/triangle-blue.svg" />
            </button>
          </div>
        </div>
      </>
    )
  }
}

export default Carousel 
