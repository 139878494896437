import React from "React";

const Content = [

  // QUESTION 1
  {
    multipleChoice: false,
    statement:
      `Only about 30% of adults are recommended to 
      get tested for HIV in their lifetime.`,
    answer: "FALSE",
    description:
      <p>
        The Centers for Disease Control and Prevention (CDC) recommends that everyone between the ages of 13 and 64 get tested for HIV at least once as part of routine healthcare.
      </p>,
    hasLink: true,
    linkText: "Who and When to Get Tested",
    linkUrl: "hiv-testing-guidelines",
    footnoteSymbol: "*"
  },

  // QUESTION 2
  {
    multipleChoice: true,
    choices: ["~15%", "~40%", "~22%", "~80%"],
    statement:
      `What percentage of new HIV infections were transmitted by people who didn't know they had HIV or were not in HIV care?`,
    answer: 
      <>
        ~80%<span style={{fontStyle: 'normal'}}>*</span>
      </>,
    description:
      <>
        <p>
          Knowing your HIV status can help prevent you from giving the virus to your partner.
        </p>
        <p>
          That's why it's so important to get tested if you are sexually active,
          so you can take the appropriate next steps.
        </p>
        <p className="footnote">
          *According to 2016 data.
        </p>
      </>,
    hasLink: true,
    linkText: "Who and When to Get Tested",
    linkUrl: "hiv-testing-guidelines",
    footnoteSymbol: <sup>†</sup>,
  },

  // QUESTION 3
  {
    multipleChoice: false,
    statement:
      `Getting tested for HIV will cost a lot of money and your results won't be private.`,
    answer: "FALSE",
    description:
      <p>
        These are common myths about HIV testing, but the truth is getting tested for HIV may be confidential or anonymous and it is often free.
      </p>,
    hasLink: true,
    linkText: "During an HIV Test",
    linkUrl: "/how-to-get-hiv-tested",
    footnoteSymbol: "*",
  },

  // QUESTION 4
  {
    multipleChoice: false,
    statement:
      `HIV is a death sentence.`,
    answer: "FALSE",
    description:
      <p>
        While there is no cure for HIV, there are medicines available to treat HIV.
        People living with HIV should talk to a healthcare professional. The sooner you start
        treatment and stick with it, the less damage HIV can do to your body,
        helping you live a longer, healthier life.
      </p>,
    hasLink: true,
    linkText: "After an HIV Test",
    linkUrl: "after-an-hiv-test",
    footnoteSymbol: "*",
  },

  // QUESTION 5
  {
    multipleChoice: true,
    choices: ["2%", "5%", "11%", "14%"],
    statement:
      `Of those living in the US with HIV, how many didn't know they had it?`,
    answer:
      <>
        14%<span style={{fontStyle: 'normal'}}>*</span>
      </>,
    description:
      <>
        <p>
          The only way to know your HIV status is to get tested. The Centers for Disease Control and 
          Prevention (CDC) recommends that everyone between the ages of 13 and 64 
          get tested for HIV at least once as part of routine healthcare.
        </p>
        <p className="footnote">
          *According to 2018 data.
        </p>
      </>,
    hasLink: true,
    linkText: "Who and When to Get Tested ",
    linkUrl: "/hiv-testing-guidelines",
    footnoteSymbol: <sup>†</sup>,
  },

  // QUESTION 6
  {
    multipleChoice: false,
    statement:
      <>
        Besides testing, there are lots of ways to tell
        if <span className="d-inline-block">someone has HIV.</span>
      </>,
    answer: "FALSE",
    description:
      <p>
        The ONLY way to know if you are living with HIV is to get tested.
      </p>,
    hasLink: true,
    linkText: "",
    linkUrl: "",
    footnoteSymbol: "*",
  }
]

export default Content;