import * as React from "react";
import { Component } from "react";

class DidYouKnow extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="container-fluid did-you-know">
        <div className="row">
          <div className="col">
            Did you know HIV is no longer <span>a death 
            sentence?</span> <a onClick={()=>{this.props.openQuiz()}}>Quiz your HIV knowledge </a>
        
          </div>
        </div>
      </div>
    )
  }
}

export default DidYouKnow
