import React, { useState, useEffect } from "react";
import '../assets/sass/app.scss';
import Quiz from '../components/home/Quiz/Index.js';
import Layout from '../components/global/Layout.js';
import { HeroMedia, HeroCopy, Cta } from '../components/home/HeroContent.js';
import QuickFacts from '../components/home/Quick-Facts/Index.js';
import Overview from '../components/home/Overview.js';
import VideoModal from '../components/global/VideoModal.js';
import DidYouKnow from '../components/global/DidYouKnow.js';
import FooterCalloutBar from "../components/global/FooterCalloutBar.js";
import Header from '../components/global/Header.js';
import scrollToElement from '../components/global/ScrollToElement';

const Home = () => {

  const [quizOpen, openQuiz] = useState(false);

  // Lock body scroll beneath Quiz overlay

  useEffect(() => {
    //const toAdd = quizOpen ? 'overflow-hidden' : 'overflow-auto';
    //const toRemove = quizOpen ? 'overflow-auto' : 'overflow-hidden';
    const toAdd = quizOpen ? 'overflow-hidden' : 'placeholder-value';
    const toRemove = quizOpen ? 'placeholder-value' : 'overflow-hidden';
    document.body.classList.add(toAdd);
    document.body.classList.remove(toRemove)
  }, [quizOpen]);


  // Todo: EXPAND CLICKABLE AREA for button links

  return (
    <>
      <Header page="home" scrollToElement={scrollToElement} />
      <VideoModal autoplay="false" />
      <Quiz show={quizOpen} hideQuiz={() => { openQuiz(false) }} />
      <Layout
        page="home"
        heroMedia={<HeroMedia />}
        heroCopy={<HeroCopy />}
        CTA={
          <Cta openQuiz={() => { openQuiz(true); }} />
        }

      >
        <Overview />
        <QuickFacts />
        <DidYouKnow openQuiz={() => { openQuiz(true); }} />
        <FooterCalloutBar />
      </Layout>
    </>
  )
}

export default Home
