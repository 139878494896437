
const scrollToElement = (elementID,offsetDesktop,offsetMobile,page) => {
    const offset = window.innerWidth >= 575 ? offsetDesktop : offsetMobile;
     var element = document.getElementById(elementID);
     var elementPosition = element.getBoundingClientRect().top;
     var offsetPosition = elementPosition + window.pageYOffset - offset;

     if (page === 'home') {
      const pinSpacerHeight = document.getElementsByClassName('pin-spacer')[0].offsetHeight;
      const positionOffset = 400;
      offsetPosition = pinSpacerHeight + positionOffset;
     }

     window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
     });
  }

export default scrollToElement;
