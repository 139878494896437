import * as React from "react";
import scrollToElement from "../global/scrollToElement";

// Hero Overlay

export const HeroMedia = () => {
  return (
    <>
      <video id="video-home" className="media d-none d-md-block" loop muted autoPlay>
        <source src= "/video/Spanish_Homepage_Hero_Desktop.mp4" type="video/mp4" />
      </video>
      <img
        className="media d-block d-md-none"
        src={"/images/home/homepage-car-hero-mobile.png"}
        alt="Two men in a car" />
    </>
  )
}

export const HeroCopy = () => {
  return (
    <>
      {/*<button type="button" className="open-video-modal" data-bs-toggle="modal" data-bs-target="#video-modal">
        <img src="/images/resources/video-press-play-thumbnail.png" alt="Press Play Video Thumbnail" />
      </button>*/}
      <h1 className="hero-copy" data-bs-toggle="modal" data-bs-target="#video-modal">
        PRESS
        <img className="play-icon" src="/images/global/Press_Play_Hero_icon.svg" alt="Click to play video"/>
         &nbsp;<br className="d-block d-lg-none" />ON MOVING FORWARD
      </h1>
      <p className="home">
        GET TESTED, KNOW YOUR HIV STATUS
      </p>
    </>
  )
}

// Hero Call To Action

export const Cta = (props) => {
  return (
    <>
      {/*<p className="home">
        You're on your way to moving forward. Once you know your HIV status, there are
        medications available to treat HIV and options to help prevent it. Talk to a doctor
        about what's right for you.
      </p>*/}
      <a
        target="_blank"
        data-interstitial-type="aidsvu"
        className="button white font-avenir-heavy opens-interstitial"
        href="https://aidsvu.org/services/#/testing">
        FIND HIV TESTING NEAR YOU
      </a>
      <a
        //onClick={()=>{props.openQuiz()}}
        href="https://together.takemehome.org/?utm_source=Gilead&utm_medium=Partner-Organic-English&utm_campaign=selftesting12_PRIORITY&utm_term=BHOC-no-campaign-term&utm_content=Partner-promotion"
        data-interstitial-type="gilead"
        className="font-avenir-heavy button opens-interstitial">
          ORDER AT-HOME TESTS*
      </a>
      <p className="footnote">
        *Eligibility requirements may apply.
      </p>
      <div
        onClick={() => {scrollToElement("nav-anchor-overview",-70,-100)}}
        className="scroll below-buttons" role="button">
        <p className="copy">
          SCROLL
        </p>
        <img className="d-inline d-lg-none icon-scroll" src="/images/global/play-icon-thick.svg" />
        <img className="d-none d-lg-inline icon-scroll" src="/images/global/play-icon.svg" />
      </div>
    </>
  )
}
