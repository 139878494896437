import React from "react";
import Carousel from './Carousel.js'

const QuickFacts = () => {

  return (
    <section className="container-fluid quick-facts bkg-white">
      <div className="container px-0">
        <div className="row">
          <div className="col">
            <Carousel />
          </div>
        </div>
      </div>
    </section>
  )
}

export default QuickFacts;

