import React, { Fragment } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const article1 = {
  className: "who-and-whent-to-get-tested",
  imgSrc: "/images/home/0.0_homepage_testing_guidelines_callout.png",
  imgAlt: "Group of friends sitting outside on porch",
  title: "HIV Testing Is for Everyone",
  copy: "The Centers for Disease Control and Prevention (CDC) recommends that everyone between the ages of 13 and 64 get tested for HIV at least once as part of routine healthcare.",
  linkCopy: "LEARN MORE",
  linkUrl: "/hiv-testing-guidelines",
}

const article2 = {
  className: "who-and-whent-to-get-tested",
  imgSrc: "/images/home/0.0_homepage_get_tested_callout.png",
  imgAlt: "Two men lying in the grass looking at each other",
  title: <span>{['Taking an HIV Test:', <br />, 'What to Expect']}</span>,
  copy: <span>{["It's OK to have questions about HIV testing. Here is some information to help you understand your testing", <span>&nbsp;</span>, 'options.']}</span>,
  linkCopy: "LEARN MORE",
  linkUrl: "/how-to-get-hiv-tested",
}

const article3 = {
  className: "who-and-whent-to-get-tested",
  imgSrc: "/images/home/0.0_homepage_after_test_callout.png",
  imgAlt: "Man at barbershop",
  title: "Getting Your Result: Taking the Next Step",
  copy: <span>{["Once you know your HIV status, you can move forward with prevention or treatment options. Talk to a healthcare professional about what's right for", <span>&nbsp;</span>, "you."]}</span>,
  linkCopy: "LEARN MORE",
  linkUrl: "/after-an-hiv-test",
}

const article4 = {
  className: "who-and-whent-to-get-tested",
  imgSrc: "/images/home/0.0_homepage_HIV_resources_callout.png",
  imgAlt: "Group of men at cookout laughing together",
  title: "Resources for You",
  copy: <span>{['No matter your HIV status, there are information and tools to help', <span>&nbsp;</span>, 'you.']}</span>,
  linkCopy: "LEARN MORE",
  linkUrl: "/resources",
}

const buildSection = (content) => {
  return (
    <article className={`col-12 col-lg-6 article ${content.className}`}>
      <img src={content.imgSrc} alt={content.imgAlt} />
      <div>
        <h2>
          {content.title}
        </h2>
        <p>
          {content.copy}
        </p>
        <a href={content.linkUrl}>
          {content.linkCopy}
        </a>
      </div>
    </article>
  )

}

class Overview extends React.Component {

  constructor() {
    super();
    this.state = {
      scrollPinOffset: undefined
    }
  }

  calculateScrollOffset = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 992) {
      return -160
    }
    else {
      return -60
    }
  }

  componentDidMount() {

    ScrollSmoother.create({
      smooth: 1,               // how long (in seconds) it takes to "catch up" to the native scroll position
      effects: true,           // looks for data-speed and data-lag attributes on elements
      smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    });

    ScrollTrigger.defaults({
      markers: false
    })

    // By default, ScrollTrigger will always refresh when the resize is trigger on a screen. At the same time, opening the virtual keyboard on Android mobile devices will trigger a resize event, which will cause the ScrollTrigger to refresh, which will cause the page to re-render. Which also means that the virtual keyboard will be closed. ScrollTrigger does offers a new configuration option since 2020 where you can control which events will trigger the refresh. By removing resize from the list, this will allow the keyboard to work correctly on Android.
    ScrollTrigger.config({ autoRefreshEvents: "visibilitychange,DOMContentLoaded,load" });

    var points = gsap.utils.toArray('.point');
    var indicators = gsap.utils.toArray('.indicator');
    var height = 70 * points.length;

    var tl = gsap.timeline({
      duration: points.length,
      scrollTrigger: {
        start: this.calculateScrollOffset().toString(),
        trigger: ".scroll-pin",
        end: "+=" + height + "%",
        scrub: true,
        id: "points",
      }
    })

    var pinner = gsap.timeline({
      scrollTrigger: {
        trigger: ".scroll-pin",
        start: this.calculateScrollOffset().toString(),
        end: "+=" + height + "%",
        pin: "main",
        id: "pinning",
      }
    });

    points.forEach(function (elem, i) {
      gsap.set(elem, { position: "absolute", top: 0 });
      tl.to(indicators[i], { backgroundColor: "orange", duration: 0.25 }, i)
      if (i == 0) {
        tl.from(elem.querySelector('img'), { autoAlpha: 1 }, i)
        tl.from(elem.querySelector('div'), { autoAlpha: 1, translateY: 0 }, i)
      }
      else {
        tl.from(elem.querySelector('img'), { autoAlpha: 0 }, i)
        tl.from(elem.querySelector('div'), { autoAlpha: 0, translateY: 100 }, i)
      }
      if (i != points.length - 1) {
        tl.to(indicators[i], { backgroundColor: "#adadad", duration: 0.25 }, i + 0.75)
        tl.to(elem.querySelector('div'), { autoAlpha: 0, translateY: -100 }, i + 0.75)
        //tl.to(elem.querySelector('img'), { autoAlpha: 0 }, i + 0.75)
      }
    });
  }

  render() {

    return (
      <Fragment>
        <section id="nav-anchor-overview" className="container-fluid overview bkg-white">
          <div className="container px-0">
            <div className="row callouts">
              <div className="col content">
                <div className="scroll-pin">
                  <div className="wrapper">
                    <div className="point one">
                      {buildSection(article1)}
                    </div>
                    <div className="point">
                      {buildSection(article2)}
                    </div>
                    <div className="point">
                      {buildSection(article3)}
                    </div>
                    <div className="point">
                      {buildSection(article4)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }

}

export default Overview;
